import React from 'react';
import styled from 'styled-components';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import DirectionsOffIcon from '@mui/icons-material/DirectionsOff';

const uiColors = {
    info : '#fffde3',
    activeMenuItem: '#fff7cc',
    white: '#ffffff',
    lightBlue: '#e2dfff',
    lightGray: '#e0e0e0',
    darkGray: '#5a5b5c',
    blue: '#1a57dc',
    green: '#7fdb74',
    sessionTag: '#7f9fad',//'#32657c',
    tips: '#e6d029',
    tipper: '#1a56db',
    purchases: '#06c24b',
    privateCamShows: '#fb8c00',
    privateCamShowsLight: '#ffad63', 
    lightRed: '#ff522f',
    faintRed: '#ffbaa1',
    faintBlue: '#e1dfff',
    superFaintBlue: '#f9f9ff',
    faintGray: '#d4d3d2'
}

export default function RedirectNotFound (props){
    console.log("In RedirectNotFound");
    return (
       <FullContentDiv>
           <div>
            <DirectionsOffIcon style={{fontSize:'30vw',fill: uiColors.lightRed}} />
            </div>
            <PlaceHolderText>Link not found</PlaceHolderText>
            
        </FullContentDiv>
    )
}

const PlaceHolderText = styled.div`
    font-size: 5vw; //20px
    font-weight: bold;
    margin-top: 20px;
    color:${uiColors.darkGray};
`

const FullContentDiv = styled.div`
    height: 100vh; /* Magic here */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`