import logo from './logo.svg';
import RedirectNotFound from './RedirectNotFound'
import { useLocation, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path="/" element={<RedirectNotFound />}  />
    </Routes>
  );
}

export default App;
